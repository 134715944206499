
.page{
  height: 100%;
  background-image: url("/img/not-found-background-1920.jpeg");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  color: var(--white);
  text-align: center;

}
@media screen and (min-width: 2200px) {
  .page{
    background-image: url("/img/not-found-background-2400.jpeg");
  }

  .title {
      font-size: 72px;
  }

  .subtitle {
      font-size: 42px;
  }

  .page p {
      font-size: 18px;
  }

  .back__button {
      height: 48px;        
  }
}

.wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-22%);
}

.title {
    font-size: 52px;
    font-weight: 600;
    margin-bottom: 28px;
    color: var(--white);
}

.subtitle {
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 14px;
    color: var(--white);
}

.page p {
    color: var(--white);
    font-size: 14px;
}

.back__button {
    margin: 38px auto;
    width: 131px;
}

.logo {
    width: 125px;
    position: absolute;
    bottom: 22.5px;
    left: calc(50% - 62.5px);
}


.logo > svg {
  color: var(--white);
}